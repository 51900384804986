<template>
    <section id="Help">
        <div v-for="bucket in buckets" :key="bucket.category" class="bucket">
            <h3>{{ bucket.category }}</h3>
            <Accordion v-for="faq in bucket.faqs" :key="faq.data.header" :header="faq.data.header">
                <prismic-rich-text :field="faq.data.body" />
            </Accordion>
        </div>
        <Search @update="refine" />
    </section>
</template>

<script>

    // Components.
    import Accordion from '@/components/Accordion.vue';
    import Search from '@/components/Search.vue';

    export default {
        name: 'Help',
        components: {
            Accordion,
            Search,
        },
        data() {
            return {
                faqs: [],
                categories: [],
                search: '',
            }
        },
        computed: {
            buckets() {

                const buckets = this.categories.map(category => {
                    const faqs = this.faqs.filter(faq => {

                        let found = this.search === '' ? 1 : 0;
                        found += faq.data.header.toUpperCase().includes(this.search.toUpperCase()) ? 1 : 0;

                        for (let row of faq.data.body) {
                            found += row.text.toUpperCase().includes(this.search.toUpperCase()) ? 1 : 0;
                        }

                        return (found > 0 && faq.data.category.data.name === category.data.name);

                    });
                    return { category: category.data.name, faqs };
                }).filter(bucket => bucket.faqs.length > 0);

                return buckets;

            },
        },
        mounted() {
            this.$store.dispatch('setNavShadow', false);
        },
        created() {

            this.$store.dispatch('updateLoading', 1);
            this.$prismic.client.query(this.$prismic.Predicates.at('document.type', 'category')).then(query => {

                this.categories = query.results;

                this.$prismic.client.query(this.$prismic.Predicates.at('document.type', 'faq'), { fetchLinks: 'category.name' }).then(query => {
                    this.faqs = query.results;
                    this.$store.dispatch('updateLoading', -1);
                });

            });

        },
        methods: {
            refine(term) {
                this.search = term;
            }
        }
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    #Help {
        padding-bottom: size(Large) * 2;
    }

    h3 {
        padding: size(Medium);
        border-bottom: 1px solid color(Emperor, 0.15);
    }

    .Search {

        position: fixed;
        bottom: 0;
        left: 0;

        width: 100%;

    }

</style>
